import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import React from "react";
import { AppContext } from "../../context";

const HowTo = () => {
  return (
    <div className="welcomeScreen">
      <div className="landingScreen">
        <h1>How To</h1>
        <h5>
          1). Go to{" "}
          <a
            href="https://docs.google.com/spreadsheets/u/0/"
            target="_blank"
            rel="noreferrer"
          >
            Google Sheets
          </a>{" "}
          and create a new sheet by pressing + in the right bottom corner of the
          screen:{" "}
        </h5>
        <img
          src="/static/step1.png"
          style={{
            width: "100%",
            border: "1px solid #000",
            borderRadius: "15px",
            maxWidth: "600px",
          }}
        />
        <h5>
          2). Create a table with Word, Translation, and Hint columns (
          <a
            href="https://docs.google.com/spreadsheets/d/1Um-E5XSlLNDWUrM8Dvm5ht2mIgQ6q7t854iy8oMGB-A/edit?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            example
          </a>
          )
          <br /> <br />
          <br />
          We'll skip the first row and stop at row 16, using only the first
          three columns. This is the area that you can see in the red square on
          the screenshot:
        </h5>
        <img
          src="/static/vocabulary_location.png"
          style={{
            width: "100%",
            border: "1px solid #000",
            borderRadius: "15px",
            maxWidth: "600px",
          }}
        />

        <h5>3). Wait until Google saved it:</h5>
        <img
          src="/static/saving.png"
          style={{
            width: "100%",
            border: "1px solid #000",
            borderRadius: "15px",
            maxWidth: "600px",
          }}
        />
        <h5>4). When finished, make it public:</h5>
        <img
          src="/static/step3.png"
          style={{
            width: "100%",
            border: "1px solid #000",
            borderRadius: "15px",
            maxWidth: "600px",
          }}
        />
        <h5>5). Copy a Google Sheet link</h5>
        <img
          src="/static/step4.png"
          style={{
            width: "100%",
            border: "1px solid #000",
            borderRadius: "15px",
            maxWidth: "600px",
          }}
        />
        <h5>6). Go to Settings and click on Change button</h5>
        <img
          src="/static/zxc.png"
          style={{
            width: "100%",
            border: "1px solid #000",
            borderRadius: "15px",
            maxWidth: "600px",
          }}
        />
        <h5>7). Enter your public Google Sheet URL and Load it</h5>
        <img
          src="/static/load_button2.png"
          style={{
            width: "100%",
            border: "1px solid #000",
            borderRadius: "15px",
            maxWidth: "600px",
          }}
        />
      </div>
    </div>
  );
};
export default HowTo;
