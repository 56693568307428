import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../axios";
import React from "react";
import Link from "../parts/Link";
import { emptyPNG, getPathById, staticServerURL } from "../../utils";
import Comments from "../grammar/comments";
import { AppContext } from "../../context";
import "../grammar/comments.scss";
import { InlineShareButtons } from "sharethis-reactjs";
import YoutubeWritingCorrection from "./youtube-writing-correction";

const WelcomeScreen = () => {
  const [appState, appDispatch] = useContext(AppContext);
  const { domain } = appState;
  const history = useHistory();

  const [uid, setUid] = useState("6eaWl"); //"EbB" -  777 // "0n" - 2
  const [comments, setComments] = useState(null);
  const [commentsSorting, setCommentsSorting] = useState("latest");

  const processComments = (data) => {
    let result = JSON.parse(JSON.stringify(data));

    result = result.filter((item) => item.parentCommentId === null);

    data
      .sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        return 0;
      })
      .forEach((item) => {
        if (item.parentCommentId) {
          result.forEach((element) => {
            if (element.id === item.parentCommentId) {
              if (element.replies === undefined) {
                element.replies = [];
              }
              element.replies.push(item);
            }
          });
        }
      });

    setComments(result);
  };

  const loadComments = async (commentId = undefined) => {
    const response = await axios.get(`/api/comments/${uid}/${commentsSorting}`);

    const data = response.data.comments;

    processComments(data);

    if (commentId) {
      setTimeout(
        (commentIdParam) => {
          const highlightComment = document.getElementById(
            commentIdParam.substring(1)
          );
          if (highlightComment) {
            highlightComment.scrollIntoView();
          }
        },
        500,
        commentId
      );
    }
  };

  useEffect(() => {
    loadComments();
  }, [uid]);

  useEffect(() => {
    if (comments !== null) {
      loadComments();
    }
  }, [commentsSorting]);

  const isCordova = !window.cordova;

  return (
    <div className="welcomeScreen">
      <div className="landingScreen">
        <svg
          viewBox="0 0 376.4 73.18"
          style={{ display: "block", margin: "0 auto 1px", width: "211px" }}
        >
          <defs>
            <style>{`.cls-1{fill:none;stroke:#e31837;stroke-miterlimit:10;stroke-width:4px;}`}</style>
          </defs>
          <path
            d="M26,74.28,12.17,31.81h5.92L24.7,52.73c1.83,5.73,3.41,10.9,4.54,15.88h.13c1.19-4.92,3-10.27,4.85-15.82l7.18-21h5.86L32.08,74.28Z"
            transform="translate(-12.17 -15.02)"
          />
          <path
            d="M77.18,58.78C77.18,70.06,69.37,75,62,75c-8.25,0-14.62-6-14.62-15.69,0-10.2,6.68-16.19,15.12-16.19C71.26,43.09,77.18,49.45,77.18,58.78ZM53,59.09c0,6.68,3.84,11.72,9.26,11.72s9.26-5,9.26-11.84c0-5.17-2.58-11.72-9.13-11.72S53,53.3,53,59.09Z"
            transform="translate(-12.17 -15.02)"
          />
          <path
            d="M105.91,73.14a20.34,20.34,0,0,1-8.76,1.77C88,74.91,82,68.67,82,59.35s6.43-16.2,16.38-16.2A18.46,18.46,0,0,1,106,44.73L104.78,49a12.94,12.94,0,0,0-6.43-1.45c-7,0-10.77,5.17-10.77,11.53,0,7.06,4.53,11.41,10.58,11.41A15.85,15.85,0,0,0,105,69Z"
            transform="translate(-12.17 -15.02)"
          />
          <path
            d="M129.28,74.28l-.44-3.85h-.19A11.33,11.33,0,0,1,119.33,75c-6.17,0-9.32-4.35-9.32-8.76,0-7.37,6.55-11.4,18.33-11.34v-.63c0-2.52-.69-7.05-6.93-7.05a15.23,15.23,0,0,0-7.94,2.26l-1.26-3.65a18.9,18.9,0,0,1,10-2.71c9.32,0,11.59,6.36,11.59,12.48V67a43.8,43.8,0,0,0,.5,7.31Zm-.81-15.56c-6-.13-12.92.94-12.92,6.86a4.91,4.91,0,0,0,5.23,5.3,7.58,7.58,0,0,0,7.37-5.11,5.74,5.74,0,0,0,.32-1.76Z"
            transform="translate(-12.17 -15.02)"
          />
          <path
            d="M142.51,74.28c.13-2.08.25-5.17.25-7.88V29.55h5.49V48.7h.12c2-3.4,5.48-5.61,10.4-5.61,7.56,0,12.91,6.3,12.85,15.56C171.62,69.55,164.75,75,158,75c-4.41,0-7.94-1.7-10.21-5.73h-.19l-.25,5Zm5.74-12.22a10,10,0,0,0,.25,2,8.55,8.55,0,0,0,8.31,6.49c5.8,0,9.27-4.72,9.27-11.72,0-6.11-3.15-11.34-9.08-11.34a8.84,8.84,0,0,0-8.44,6.81,11.31,11.31,0,0,0-.31,2.26Z"
            transform="translate(-12.17 -15.02)"
          />
          <path
            d="M204.13,66c0,3.15.06,5.92.25,8.32h-4.91l-.32-5H199A11.45,11.45,0,0,1,188.94,75c-4.78,0-10.52-2.65-10.52-13.36V43.78H184V60.67c0,5.8,1.76,9.7,6.8,9.7a8,8,0,0,0,7.31-5,8.23,8.23,0,0,0,.5-2.83V43.78h5.55Z"
            transform="translate(-12.17 -15.02)"
          />
          <path
            d="M213.32,29.55h5.55V74.28h-5.55Z"
            transform="translate(-12.17 -15.02)"
          />
          <path
            d="M245.08,74.28l-.45-3.85h-.18A11.36,11.36,0,0,1,235.12,75c-6.17,0-9.32-4.35-9.32-8.76,0-7.37,6.55-11.4,18.33-11.34v-.63c0-2.52-.69-7.05-6.93-7.05a15.23,15.23,0,0,0-7.94,2.26L228,45.8a18.92,18.92,0,0,1,10-2.71c9.32,0,11.59,6.36,11.59,12.48V67a42.69,42.69,0,0,0,.51,7.31Zm-.82-15.56c-6-.13-12.92.94-12.92,6.86a4.91,4.91,0,0,0,5.23,5.3,7.58,7.58,0,0,0,7.37-5.11,5.74,5.74,0,0,0,.32-1.76Z"
            transform="translate(-12.17 -15.02)"
          />
          <path
            d="M258.56,53.3c0-3.59-.07-6.68-.25-9.52h4.85l.19,6h.25c1.38-4.1,4.72-6.68,8.44-6.68a5.77,5.77,0,0,1,1.57.19v5.23a8,8,0,0,0-1.89-.19c-3.9,0-6.67,3-7.43,7.12A15.25,15.25,0,0,0,264,58V74.28h-5.48Z"
            transform="translate(-12.17 -15.02)"
          />
          <path
            d="M282.75,43.78l6.68,18c.69,2,1.45,4.41,1.95,6.24h.13c.56-1.83,1.19-4.16,1.95-6.36l6.05-17.9h5.86l-8.32,21.74c-4,10.46-6.68,15.81-10.46,19.09a15.06,15.06,0,0,1-6.8,3.59l-1.39-4.66a14.56,14.56,0,0,0,4.85-2.71,16.88,16.88,0,0,0,4.66-6.17,3.81,3.81,0,0,0,.44-1.33,4.9,4.9,0,0,0-.37-1.45L276.7,43.78Z"
            transform="translate(-12.17 -15.02)"
          />
          <path
            d="M310.4,74.28V43.78H316v30.5Z"
            transform="translate(-12.17 -15.02)"
          />
          <path
            d="M325.15,52c0-3.15-.07-5.74-.26-8.26h4.92l.31,5h.13a11.22,11.22,0,0,1,10.08-5.73c4.22,0,10.77,2.52,10.77,13V74.28h-5.54V56.7c0-4.91-1.83-9-7.06-9a7.87,7.87,0,0,0-7.43,5.67,8,8,0,0,0-.38,2.58V74.28h-5.54Z"
            transform="translate(-12.17 -15.02)"
          />
          <path
            d="M386.38,43.78c-.12,2.21-.25,4.67-.25,8.38V69.87c0,7-1.39,11.28-4.35,13.92s-7.24,3.66-11.09,3.66c-3.65,0-7.68-.89-10.14-2.52l1.39-4.23a17.15,17.15,0,0,0,8.94,2.4c5.67,0,9.83-3,9.83-10.65v-3.4h-.12a10.9,10.9,0,0,1-9.71,5.1c-7.56,0-13-6.42-13-14.87,0-10.33,6.74-16.19,13.73-16.19,5.29,0,8.19,2.77,9.51,5.29h.13l.25-4.6Zm-5.73,12a7.63,7.63,0,0,0-.32-2.52,8,8,0,0,0-7.74-5.86c-5.3,0-9.08,4.47-9.08,11.53,0,6,3,11,9,11a8.11,8.11,0,0,0,7.69-5.67,9.79,9.79,0,0,0,.44-3Z"
            transform="translate(-12.17 -15.02)"
          />
          <line class="cls-1" x1="296.05" y1="17.54" x2="336.03" y2="1.86" />
          <line class="cls-1" x1="375.67" y1="17.95" x2="334.73" y2="1.86" />
        </svg>
        <b className="slogan">Learn new words, while having fun</b>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {isCordova && (
          <>
            <div className="firstScreen">
              <a
                href="#video-popup"
                onClick={(e) => {
                  e.preventDefault();
                  appDispatch({
                    popup: "video-writing-correction",
                  });
                }}
                style={{
                  textDecoration: "none",
                  display: "block",
                  cursor: "pointer",
                }}
              >
                <img
                  alt="Vocabularying"
                  src="/static/vocab3.png"
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    border: "1px solid #888",
                    borderRadius: "10px",
                  }}
                />
              </a>

              <h2
                style={{
                  marginTop: "0",
                }}
              >
                Fun, and effective way to learn new words!
              </h2>
            </div>

            <div
              style={{
                margin: "200px 0",
              }}
            >
              <h2>
                1. <Link href="/how-to">Create</Link> a Public Google Sheet{" "}
              </h2>
              <h2>2. Load it in the Game Settings</h2>
              <h2>
                3. Play Games and Learn New Words
                {/* (being able to Guess Meaning) */}
              </h2>
            </div>
          </>
        )}

        <div>
          <div
            className="gameList"
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "row",
            }}
          >
            <style>{`
              
              @media (max-width: 800px) {
.gameList{
  flex-direction: column !important;
}
.gameList div {
  padding-bottom: 50px;
}
              }

            `}</style>
            <div>
              <Link href="/game/candy">
                <img
                  src="/static/candy-logo.jpg"
                  className="gameLogo"
                  alt="candy"
                />
              </Link>
              <h3>For Passive Vocabulary</h3>
            </div>
            <div>
              <Link href="/game/space">
                <img
                  src="/static/space-logo2.jpg"
                  className="gameLogo"
                  alt="space"
                />
              </Link>
              <h3>For Active Vocabulary</h3>
            </div>
          </div>
          {isCordova && (
            <div>
              <br />
              <br />
              <br />
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.vocabularying"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/static/google-play-badge.png"
                    width={190}
                    alt="Android App"
                  />
                </a>
              </div>
              <br />
              <br />
              <br />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
