import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import React from "react";
import { AppContext } from "../../context";
import { SlidingTabBar } from "../parts/SlidingTabBar";
import axios from "../../axios";

const allTabs = [
  { id: "30", name: "Monthly", price: "$3.88" },
  { id: "90", name: "Annually", price: "$2.44" },
];

const Pricing = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(1);
  const [appState, appDispatch] = useContext(AppContext);

  const galka = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
      height="20"
      width="20"
      style={{ marginBottom: "-4px" }}
    >
      <path
        fill-rule="evenodd"
        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );

  return (
    <div className="welcomeScreen">
      <div className="landingScreen">
        <h1>Pricing</h1>
        <style>{`
    
.plan{
    margin: 0 auto;
    padding: 30px 30px;
    max-width: 287px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 8px rgb(0 0 0 / 15%);
    text-align: center;
    border-radius: 10px;
    font-family: "Arial", "Helvetica", "Roboto", sans-serif;

      flex-shrink: 0; /* Prevent shrinking */
  flex-grow: 0; /* Prevent growing */
  align-self: stretch; 
}
    .planHeader{
    font-size: 26px;
    }

        .plan ul { 
    width: 220px;
    margin: 10px auto;
    }

    .plan ul li{ 
    margin: 13px 0;
    text-align: left;
    }

    .subscriptionButton{
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    padding: 11px 28px;
    font-size: 21px;
    letter-spacing: 0.7px;
    cursor: pointer;
    font-weight: 300;
    }

        .subscriptionButton:hover{
         background-color: #444;
        }

        .plansWrapper {
        display: flex;
               flex-direction: row;
        }

        @media (max-width: 700px) {
            .plansWrapper {
            flex-direction: column-reverse;
            }

            .plansWrapper .plan {
            margin-bottom: 30px;
            }
        }
`}</style>
        <div>
          <SlidingTabBar
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={(val) => {
              setActiveTabIndex(val);
            }}
            allTabs={allTabs}
          />

          <div className="plansWrapper" style={{ display: "flex" }}>
            <div
              className="plan"
              style={{
                height: "210px",
                marginBottom: "30px",
              }}
            >
              <div className="planHeader">Free</div>
              <div style={{ margin: "30px auto 10px" }}>
                <b style={{ fontSize: "30px" }}>$0</b> / month
              </div>

              <br />
              <ul>
                <li>{galka} Test Vocabulary</li>
                <li>{galka} IELTS Band 5 Vocabulary</li>
              </ul>
            </div>

            <div className="plan">
              <div className="planHeader">
                <svg
                  viewBox="0 0 28 28"
                  fill="none"
                  height={20}
                  style={{
                    marginBottom: "-5px",
                    marginLeft: "-6px",
                    height: "28px",
                    marginRight: "5px",
                  }}
                >
                  <path
                    d="M20.75 3C21.0557 3 21.3421 3.13962 21.5303 3.3746L21.6048 3.48102L25.8548 10.481C26.0556 10.8118 26.0459 11.2249 25.8395 11.5435L25.7634 11.6459L14.7634 24.6459C14.3906 25.0865 13.7317 25.1159 13.3207 24.7341L13.2366 24.6459L2.23662 11.6459C1.98663 11.3505 1.93182 10.941 2.08605 10.5941L2.14522 10.481L6.39522 3.48102C6.55388 3.21969 6.82182 3.04741 7.1204 3.00842L7.25001 3H20.75ZM17.515 12H10.484L13.999 20.672L17.515 12ZM22.844 12H19.673L16.756 19.195L22.844 12ZM8.326 12H5.155L11.242 19.193L8.326 12ZM9.674 5H7.81101L4.775 10H8.245L9.674 5ZM16.246 5H11.753L10.324 10H17.675L16.246 5ZM20.188 5H18.325L19.754 10H23.224L20.188 5Z"
                    fill="currentColor"
                  />
                </svg>
                Premium
              </div>
              <div style={{ margin: "30px auto" }}>
                <b style={{ fontSize: "30px" }}>
                  {allTabs[activeTabIndex].price}
                </b>{" "}
                / month{" "}
                {activeTabIndex === 1 && (
                  <div
                    style={{
                      marginTop: "16px",
                      color: "#b3b3b3",
                    }}
                  >
                    billed $29.28 per year
                  </div>
                )}
              </div>
              <div
                className="subscriptionButton"
                onClick={async () => {
                  if (appState?.user?.id > 1) {
                    const itemId =
                      activeTabIndex === 1 ? "vocabulary_360" : "vocabulary_30";

                    const response = await axios.get(
                      `/api/shop/mono-generate-link/${itemId}`
                    );

                    if (response.data) {
                      window.location.href = response.data;
                    }
                  } else {
                    appDispatch({
                      popup: "sign-in",
                      isDrawerOpen: false,
                    });
                  }
                }}
              >
                Subscribe
              </div>
              <ul style={{ marginTop: "30px" }}>
                <li>{galka} Add Your Vocabularies</li>
                <li>{galka} All IELTS Vocabularies</li>
                <li>{galka} iOS/Android apps</li>
                <li>{galka} Priority support</li>
              </ul>
            </div>
          </div>
        </div>
        {/* <h2>
          1). Go to{" "}
          <a
            href="https://docs.google.com/spreadsheets/u/0/"
            target="_blank"
            rel="noreferrer"
          >
            Google Sheets
          </a>{" "}
          and create a new sheet by pressing + in the right bottom corner of the
          screen:{" "}
        </h2>
        <img
          src="/static/step1.png"
          style={{
            width: "100%",
            border: "1px solid #000",
            borderRadius: "15px",
            maxWidth: "600px",
          }}
        />
        cecsdc */}
      </div>
    </div>
  );
};
export default Pricing;
